<template>
    <div>
        <common-header v-if="from !== 'jk' && from !== 'gh'" :toIndex="from == 'gh'">{{currentTitle}}</common-header>
        <div :class="isAddContainer ? 'container' : 'jk-container'">
            <a v-if="from == 'jk'" class="btn" @click="toIndex"></a>
            <iframe v-if="from" ref="myIframe" :src="fromObj[from]" width="100%" height="800" frameborder="0" scrolling="auto" id="iframename" name="iframename"></iframe>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            id: '',
            from: '',
            jbSrc: 'https://appweb.dalutone.cn/app/twjb?source=weChat-official-account&to=Twjb&code=08189tll2yIkc74xDmll22cZhr089tlk',
            jkSrc: 'https://taiyitong.hxcx.com.cn/manage-health/',
            // zxSrc: 'https://weixin2.diandianys.com/zsyf/',
            zxSrc: 'http://wdoctoronline.hxcx.com.cn/#/consult/service/describe/NEW2021/22?partner=tprs&productId=4122&userId=023ede1d960868af9d434fce409e4235',
            ghSrc: '',
            bdSrc: 'https://m.yihu.com/index.php/Act/DailyHealth/index.html',
            kbjSrc: 'http://www.fjxzj.com/product/5/',
            tjSrc: 'https://taiyitong.hxcx.com.cn/yuanhua/healthCheck',
            yjhcSrc: 'http://www.fjxzj.com/product/5/',
            dlzskfSrc: 'https://weixin2.diandianys.com/zsyf/',
            zjyySrc: 'https://taiyitong.hxcx.com.cn/yuanhua/expertAppointment',
            // zxffwzSrc: 'https://mcenter.yihu.com/Ask/web/ask/firstask.html?doctorgroupid=3',
            // zxmfzxSrc: 'https://mcenter.yihu.com/Ask/web/ask/firstask.html?doctorgroupid=20&subflag=3001',
            ddSrc: 'https://msupsale.ccic-net.com.cn/#/nonCar/millionMedicalCare?shareUrlId=v669jQhiBr&marketingType=1&code=0610FnGa1ZBoeB0Tp6Ja1swkab20FnGT',
            titleList: ['線下掛號', '健保核销', '在線診療'],
            fromObj: {
                'zx': this.zxSrc,
                'gh': this.ghSrc,
                'jb': this.jbSrc,
                'jk': this.jkSrc,
                'bd': this.bdSrc,
                'kbj': this.kbjSrc,
                'yjhc': this.yjhcSrc,
                'dlzskf': this.dlzskfSrc,
                // 'zxffwz': this.zxffwzSrc,
                // 'zxmfzx': this.zxmfzxSrc,
                'tj': this.tjSrc,
                'dd': this.ddSrc,
                'zjyy': this.zjSrc
            }
        }
    },
    computed: {
        isAddContainer() {
            // 如果不需要padding-top: 44px;則在noContainerList中添加
            let noContainerList = ['jk', 'gh']
            if (noContainerList.indexOf(this.from) === -1) {
                return true
            }else {
                return false
            }
        },
        currentTitle() {
            let titleObj = {
                'zx': '在線診療',
                // 'gh': '線下掛號',
                'jb': '健保核销',
                'bd': '健康知識',
                'kbj': '康佰家',
                'yjhc': '又見回春',
                'dlzskf': '大陸診所開方',
                // 'zxffwz': '在線付費問診',
                // 'zxmfzx': '在線免費咨詢',
                'tj': '體檢服務',
                'dd': '保險',
                'zjyy': '專家預約'
            }

            return titleObj[this.from]
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.from = this.$route.query['from']
        this.id = this.$route.query['id']
        this.ghSrc = 'https://wxauth.yihu.com/apiweb/tp.html?point=gh&thirdPartyUserId=' + this.$route.query['id'] + '&appId=9001217&sourceType=2'
        this.recordSrc = "https://wxauth.yihu.com/apiweb/tp.html?point=ghjl&thirdPartyUserId=" + this.$route.query['id'] + "&appId=9001217&sourceType=2"
        this.doctorSrc = "https://wxauth.yihu.com/apiweb/tp.html?point=wdys4grzx&thirdPartyUserId=" + this.$route.query['id'] + "&appId=9001217&sourceType=2"
        this.bdSrc = "https://wxauth.yihu.com/apiweb/tp.html?point=mrjk4qyjkgj&thirdPartyUserId=" + this.$route.query['id'] + "&appId=9001217&sourceType=2"
        this.fromObj = {
            'zx': this.zxSrc,
            'gh': this.ghSrc,
            'jb': this.jbSrc,
            'jk': this.jkSrc,
            'bd': this.bdSrc,
            'kbj': this.kbjSrc,
            'yjhc': this.yjhcSrc,
            'dlzskf': this.dlzskfSrc,
            'zxffwz': this.zxffwzSrc,
            'zxmfzx': this.zxmfzxSrc,
            'tj': `${this.tjSrc}?token=${localStorage.getItem('user_token')}`,
            'dd': this.ddSrc,
            'zjyy': `${this.zjyySrc}?token=${localStorage.getItem('user_token')}`

        }
    },
    methods: {
        toIndex() {
            let myIframe = this.$refs['myIframe']
            // let iframes = document.getElementsByTagName("iframe");
            // console.log('地址,',iframes[0].contentWindow.location.href)
            console.log('myIframe,', myIframe)
            this.$router.push('/index')
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    padding-top: 44px;
}

.jk-container {
    // margin-top: -66px;
}
.btn {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
}
</style>